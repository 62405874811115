<template>
  <div class="container">
      <div class="form">
          <div class="title-box">
              <img src="../assets/images/logo1.webp" alt="">
              <p>易方汇智官网后台</p>
          </div>
          <div class="input-box">
              <i class="el-icon-user"></i>
              <input type="text" v-model="account " placeholder="手机号">
          </div>
          <div class="input-box" style="margin-top:9%">
              <i class="el-icon-lock" style="color:#7194F3"></i>
              <input type="password" v-model="password" placeholder="密码">
          </div>
          <button @click="login">登 录</button>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            account :'',
            password:'',
        }
    },
    created(){
        document.body.style.height = "100%"
        document.documentElement.style.height = "100%"
    },
    methods:{
        login(){
            if(this.account ==''||this.password==''){
                this.$message({
                    message: '账号或密码不能为空！',
                    type: 'error'
                })
                return;
            }
            this.$api.login({
                account :this.account ,
                password:this.password
            }).then(res=>{
                if(res.data.code == 100){
                    this.$message({
                        message: res.data.message,
                        type: 'error'
                    })
                }else{
                    this.$message({
                        message: res.data.message,
                        type: 'success'
                    });
                    async function saveLocalStorage () {
                        localStorage.setItem('user',JSON.stringify(res.data.data));
                    }
                    saveLocalStorage().then(()=>{
                        this.$router.push('/webback')
                    })
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.container{
    width: 100%;
    height: 100%;
    background: url(../assets/images/loginBack.webp) no-repeat center center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.form{
    width: 470px;
    height: 570px;
    background: rgba($color: #fff, $alpha: 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-box{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        font-weight: bold;
        color:#4D97FF;
        margin-top: 18%;
        margin-bottom: 10%;
        letter-spacing: 1px;
        p{margin-left: 1.5%;}
    }
    .input-box{
        border-bottom: 1px solid #676767;
        width: 65%;
        display: flex;
        align-items: center;
        padding: 10px;
        input{
            height: 30px;
            width: 100%;
            margin-left: 5%;
            background: none;
            font-size: 15px;
        }
        i{
            color: #EDB941;
            font-size: 30px;
        }
    }
    button{
        background: #4D97FF;
        color: #fff;
        width: 65%;
        border-radius: 4px;
        height: 40px;
        margin-top: 11%;
        font-size: 17px;
        font-weight: bold;
        cursor: pointer;
    }
    button:active{
        background: rgba($color: #4D97FF, $alpha: 0.8);
    }
}
</style>